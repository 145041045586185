import email from "../assets/email.png";
import mobile from "../assets/mobile.png";
import profile from "../assets/profile.png";
import circle from "../assets/circle.svg";
import logo_tae from "../assets/logo_tae.png";
import logo_tae_2 from "../assets/logo_tae_2.png";
import react from "../assets/react.png";
import javascript from "../assets/javascript.png";
import typescript from "../assets/typescript.png";
export default {
  logo_tae,
  logo_tae_2,
  email,
  mobile,
  profile,
  circle,
  react,
  javascript,
  typescript,
};
